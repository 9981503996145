import React, { useState } from 'react'
import { Box } from "./Styles/index"
import { APPACTIONS } from "./App"

interface SiteSearchProps {
    dispatch: React.Dispatch<APPACTIONS>,
    keywords: string[],
    rules: boolean,
    mod: boolean,
    websitesUpload: (websitesArray: string[]) => void,
    keywordsUpload: (keywordsArray: string[]) => void,
    deleteData: () => void
}

const SiteSearch = ({ deleteData, websitesUpload, keywordsUpload, keywords, rules, dispatch, mod }: SiteSearchProps) => {
    const [websiteString, setWebsiteString] = useState<string>("")
    const [keywordsString, setKeywordsString] = useState<string>("")

    //registro i cambiamenti dell'input string websites
    const websiteInput: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setWebsiteString(e.target.value)
    }

    //registro i cambiamenti dell'input string keywords
    const keywordsInput: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setKeywordsString(e.target.value)
    }

    //splitto la stringa in un array e lo salvo per inviarlo alla lista
    const addWebsites: React.MouseEventHandler<HTMLInputElement> = () => {
        //per i siti web splitto la nuova linea
        const websitesArray: string[] = websiteString ? websiteString.split("\n") : []
        //per le keywords soggette a regole booleane considero gli spazi
        const keywordsArray: string[] = keywordsString ? keywordsString.split(" ") : []
        websitesUpload(websitesArray)
        keywordsUpload(keywordsArray)
    }

    //cambia visualizzazione
    const toggleView = () => {
        dispatch({ type: `toggle_view` })
    }

    //mostra o nascondi regole
    const toggleRules = () => {
        dispatch({ type: `toggle_rules` })
    }

    return (
        <Box justify="center" align="center" direction="column">
            <Box justify="center" align="flex-start" direction="row">
                <Box justify="center" align="flex-start" direction="column">
                    <div className="title">Websites</div>
                    <textarea value={websiteString} onChange={websiteInput} />
                </Box>
                {mod &&
                    <>
                        <div className="space" />
                        <Box justify="center" align="flex-start" direction="column">
                            <div className="title">Keywords</div>
                            <textarea value={keywordsString} onChange={keywordsInput} />
                        </Box>
                    </>
                }
            </Box>
            <div className="space-half" />
            <Box justify="center" align="flex-start" direction="row">
                <input type="submit" onClick={addWebsites} value="cerca" />
                <div className="space-half" />
                <input type="submit" onClick={toggleView} value="cambia modalità" />
                <div className="space-half" />
                <input type="submit" onClick={toggleRules} value={rules ? "nascondi regole" : "mostra regole"} />
                <div className="space-half" />
                <input type="submit" onClick={deleteData} value="cancella dati" />
            </Box>
            <div className="space-half" />
            {keywords.length > 0 && <Box justify="center" align="flex-start" direction="row">
                <span className="keyword">Keywords usate:</span> {keywords.map((keyword, i) => <span key={i} className="keyword">{keyword}</span>)}
            </Box>}
            {rules && <Box justify="center" align="flex-start" direction="column">
                <div className="regole">
                    🕹 Regole di utilizzo:
            <span className="regola">
                        Ogni sito, deve andare a nuova linea
            </span>
                    <span className="regola">
                        📚 Keywords:
            </span>
                    <span className="regola">
                        albero fiore {'=>'} albero O fiore
            </span>
                    <span className="regola">
                        albero+fiore {'=>'} albero E fiore
            </span>
                    <span className="regola">
                        albero-fiore {'=>'} albero SENZA fiore
            </span>
            🗄️ Dati storati:
            <span className="regola">
                        Ad ogni pressione di Cerca i dati vengono storati nel vostro browser
            </span>
                    <span className="regola">
                        ogni sito cancellato al click, aggiorna la lista di siti nello store
            </span>
                    <span className="regola">
                        per una nuova ricerca premere "Cancella Dati"
            </span>
                </div>
            </Box>
            }
        </Box>
    )
}

export default SiteSearch