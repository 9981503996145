import React, { useReducer, useEffect } from 'react'
import SiteSearch from "./SiteSearch"
import DeepSearchContainer from "./lists/DeepSearchContainer"
import WebsitesContainer from "./lists/WebsitesContainer"
import { Box, AppStyle } from "./Styles/index"

//DB
import localforage from "localforage"

interface AppState {
    websitesContainer: string[],
    keywords: string[],
    view: boolean,
    rules: boolean
}

const initialState: AppState = { websitesContainer: [], keywords: [], view: true, rules: false };

export type APPACTIONS =
    | { type: "add_websites", payload: string[] }
    | { type: "add_keywords", payload: string[] }
    | { type: "toggle_view" }
    | { type: "toggle_rules" }

function reducer(state: AppState, action: APPACTIONS) {
    switch (action.type) {
        case `add_websites`:
            return { ...state, websitesContainer: [...action.payload] };
        case `add_keywords`:
            return { ...state, keywords: [...action.payload] };
        case `toggle_view`:
            return { ...state, view: !state.view };
        case `toggle_rules`:
            return { ...state, rules: !state.rules };
        default:
            throw new Error();
    }
}

const App = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        //creo database o lo leggo
        localforage.config({
            name: 'match-machine'
        });

        //prendo dati dal DB oppure setto tutto su []
        localforage.getItem<string[]>('websites').then(function (value: string[] | null) {
            dispatch({ type: 'add_websites', payload: value ? value : [] })
        })

        localforage.getItem<string[]>('keywords').then(function (value: string[] | null) {
            dispatch({ type: 'add_keywords', payload: value ? value : [] })
        })

    }, [])

    //aggiunge i siti al database e aggiorna lo stato
    const addWebsites = (websitesArray: string[]) => {
        localforage.setItem('websites', websitesArray).then(function (value) {
            dispatch({ type: 'add_websites', payload: value })
        })
    }

    //filtra siti alla cancellazione
    const filterWebsites = (deletedSite: string) => {
        const websites = state.websitesContainer.filter(value => value !== deletedSite)
        localforage.setItem('websites', websites).then(function (value) {
            dispatch({ type: 'add_websites', payload: value })
        })
    }

    //aggiunge le nuove keywords e aggiorna lo stato
    const addKeywords = (keywordsArray: string[]) => {
        localforage.setItem('keywords', keywordsArray).then(function (value) {
            dispatch({ type: 'add_keywords', payload: value })
        })
    }

    //elemina la ricerca
    const deleteData = () => {
        localforage.clear().then(function () {
            dispatch({ type: 'add_websites', payload: [] })
            dispatch({ type: 'add_keywords', payload: [] })
        })
    }

    return (
        <AppStyle>
            <Box justify="center" align="center" direction="column">
                <div className="match-title">
                    🤜
                    <span style={{ color: "#e40303" }}> The </span>
                    <span style={{ color: "#ff8c00" }}>Official </span>
                    <span style={{ color: "#ffed00" }}>and </span>
                    <span style={{ color: "#008026" }}>Proud </span>
                    <span style={{ color: "#004dff" }}>Match </span>
                    <span style={{ color: "#750787" }}>Machine™ </span>
                    🤛
                </div>
                <div className="match-subtitle match-title">
                    a 90s web app
                </div>
                <div className="match-subtitle match-title">
                    {state.view ? "Modalità filtri Google" : "Modalità lista siti"}
                </div>
                <div className="space" />
                <SiteSearch
                    mod={state.view}
                    dispatch={dispatch}
                    keywords={state.keywords}
                    rules={state.rules}
                    websitesUpload={addWebsites}
                    keywordsUpload={addKeywords}
                    deleteData={deleteData}
                />
                <div className="space" />
                {state.websitesContainer.length > 0 && <Box justify="center" align="center" direction="row">
                    {state.view ?
                        <DeepSearchContainer
                            filterWebsites={filterWebsites}
                            websites={state.websitesContainer}
                            keywords={state.keywords}
                        /> :
                        <WebsitesContainer
                            filterWebsites={filterWebsites}
                            websites={state.websitesContainer}
                        />
                    }
                </Box>}
            </Box>
        </AppStyle>
    )

}

export default App;