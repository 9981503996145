import React from 'react'
import LinkElement from "./LinkElement"
import {Box} from "../Styles/index"

interface DeepSearchContainerProps {
    websites: string[],
    filterWebsites: (deletedSite: string) => void
}

const DeepSearchContainer = ({filterWebsites, websites} : DeepSearchContainerProps) => {
    return(
        <Box justify="center" align="center" direction="column">
            <div className="title">Domini</div>
            <div className="space"></div>
            <Box justify="center" align="flex-start" direction="row">
                { websites.map((website, i) => <LinkElement key={i} mode="web" filterWebsites={filterWebsites}>{website}</LinkElement>) }
            </Box>
        </Box>
    )
};

export default DeepSearchContainer;