import React from 'react'

interface LinkElementProps {
    mode: string,
    keywords?: string[],
    filterWebsites: (deletedSite: string) => void
}

const LinkElement: React.FC<LinkElementProps> = ({ filterWebsites, mode, keywords, children}) => {

    const showAndDelete: any = (website: string) => {
        let linkToOpen;
        if(mode === 'web'){
            linkToOpen = `https://${website}`
        }
        else{
            linkToOpen = `https://www.google.com/search?q=${keywords!.join(" ").trim().replace(/\s/g, `+OR+`)}+site:${website}`
        }
        window.open(linkToOpen, '_blank');
        filterWebsites(website)
    }

    return(
        <span className='link-element' onClick={() => showAndDelete(children)}>{children}</span>
    )

}

export default LinkElement;