import styled from "styled-components";

//theme
const borderRadius = '4px';
const mainColor = '#1d79bb';
const secondaryColor = '#f7a434';

interface BoxProps {
    direction: string,
    align: string,
    justify: string
}

export const Box = styled.div<BoxProps>`
    display: flex;
    flex-direction: ${props => props.direction};
    justify-content: ${props => props.justify};
    align-items: ${props => props.align};
    flex-wrap: wrap;
`

export const AppStyle = styled.div`
    height: 100vh;
    font-family: 'IBM Plex Sans', sans-serif;

    & > div {
        box-sizing: border-box;
        position: relative;
        top:10px;
    }

    textarea,input,button{
        border-color: ${secondaryColor};
        color: ${mainColor};
        border-radius: ${borderRadius};
        font-size: 1rem;
        font-family: inherit;
    }

    input[type=submit]{
        background-color: white;
        height: auto;
        width: auto;
        font-size: 1.2rem;
    }

    .match-title{
        margin-bottom: 5px;
        text-shadow: 2px 2px #3E8DC5;
        text-align: center;
        font-size: 45px;
        color: #f7a434;
        font-family: Ubuntu, arial;
    }

    .match-subtitle{
        font-size: 22px;
    }

    .link-element{
        cursor: pointer;
        color: ${secondaryColor};
        margin-right: 20px;
    }

    .title{
        font-size: 1.5rem;
        color: ${secondaryColor};
    }

    .main-color{
        color: #1d79bb
    }

    .space{
        height: 2rem;
        width: 2rem;
    }

    .space-half{
        height: 1rem;
        width: 1rem;
    }

    .regole{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        color: #65A6D4;
    }

    .regola{
        margin: 5px
    }

    .keyword{
        color: ${secondaryColor};
        margin-right: 5px;
    }
`
